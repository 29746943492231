<template>
  <modify-apply-block
    data-type="rew"
    ref="rew_p"
    :dataFileParm="{docName: '奖励信息', isDoc: true}"
    :getTitle="getTitle"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :initAddFormData="initAddFormData"
    :form-parms-add="formParmsAdd"
    block-name="奖励信息"
    ref-name="rew"
    :worker-id="workerId">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  rewPunRequest
} from '../../../api'

export default {
  components: {
    ModifyApplyBlock
  },
  data () {
    return {
      workerId: null
    }
  },
  created () {
    this.workerId = this.$router.history.current.query.workerId
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs['rew_p'].openForm) {
      this.$refs['rew_p'].openForm = false
      next(false)
    } else {
      next()
    }
  },
  methods: {
    initAddFormData () {
      return {
        workerId: this.workerId,
        type: 'rew'
      }
    },
    async getEdit () {
      if (this.workerId) {
        return await rewPunRequest.getEdit({
          type: 'rew',
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      return await rewPunRequest.saveEdit(Object.assign({}, parm, {
        dataId: parm.id,
        id: undefined
      }))
    },
    async addEdit (parm) {
      return await rewPunRequest.addEdit(parm)
    },
    getTitle (data) {
      return data.title || ''
    }
  },
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          label: '奖励类型',
          selectDatas: this.$store.getters.selectDatas['rew_type_list'].map(v => ({value: v.key, label: v.label})),
          key: 'title',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '奖励名称',
          key: 'pzwj',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '奖励批准日期',
          key: 'pzsj'
        },
        {
          type: 'input',
          label: '奖励批准机关名称',
          key: 'pzdw'
        },
        {
          type: 'select',
          label: '授予荣誉称号的级别',
          selectDatas: this.$store.getters.selectDatas['rew_level_list'].map(v => ({value: v.key, label: v.label})),
          key: 'level'
        },
        {
          type: 'input',
          label: '奖励原因',
          key: 'reson'
        },
        {
          type: 'datePicker',
          label: '奖励撤销日期',
          key: 'jcsj'
        },
        {
          type: 'input',
          label: '奖励撤销原因',
          key: 'jcyy'
        },
        {
          type: 'input',
          label: '奖励文件文号',
          key: 'pzwjh'
        }]
        return data
      }
    }
  }
}
</script>
